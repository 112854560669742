<template>
  <v-container fluid style="height: 95vh">
    <v-row>
      <v-col class="col-12 col-md-6">
        <v-menu
          v-model="menu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              hide-details
              v-model="startDateFormatted"
              label="От"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="startDate"
            locale="ru-RU"
            first-day-of-week="1"
            @input="menu = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col class="col-12 col-md-6">
        <v-menu
          v-model="menu2"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              hide-details
              v-model="endDateFormatted"
              label="До"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="endDate"
            locale="ru-RU"
            first-day-of-week="1"
            @input="menu2 = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-12 col-md-6">
        <v-btn @click="downloadItem('payment')">Отчет по платежам</v-btn>
      </v-col>
      <v-col class="col-12 col-md-6">
        <v-btn @click="downloadItem('charging')">Отчет по зарядкам</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "create",
  data() {
    return {
      menu: false,
      menu2: false,
      startDate: null,
      endDate: null,
      startDateFormatted: null,
      endDateFormatted: null,
    };
  },
  watch: {
    startDate() {
      this.startDateFormatted = this.formatDate(this.startDate);
      if (this.startDate > this.endDate) {
        this.endDate = this.startDate;
      }
    },
    endDate() {
      if (this.endDate < this.startDate) {
        this.endDate = this.startDate;
      }
      this.endDateFormatted = this.formatDate(this.endDate);
    },
  },
  methods: {
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");

      return `${day}-${month}-${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split("-");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    downloadItem(report_type) {
      switch (report_type) {
        case "payment":
          this.$store
            .dispatch("create_payment_report", {
              date_from: this.startDate,
              date_to: this.endDate,
            })
            .then((response) => {
              const filename = response.data.result.report_name;
              this.axios
                .get(`reports/${filename}`, { responseType: "blob" })
                .then((response) => {
                  const blob = new Blob([response.data], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
                  });
                  const link = document.createElement("a");
                  link.href = URL.createObjectURL(blob);
                  link.download = filename;
                  link.click();
                  URL.revokeObjectURL(link.href);
                });
            });
          break;
        case "charging":
          this.$store
            .dispatch("create_charging_report", {
              date_from: this.startDate,
              date_to: this.endDate,
            })
            .then((response) => {
              const filename = response.data.result.report_name;
              this.axios
                .get(`reports/${filename}`, { responseType: "blob" })
                .then((response) => {
                  const blob = new Blob([response.data], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
                  });
                  const link = document.createElement("a");
                  link.href = URL.createObjectURL(blob);
                  link.download = filename;
                  link.click();
                  URL.revokeObjectURL(link.href);
                });
            });
          break;
        default:
          this.$store
            .dispatch("create_payment_report", {
              date_from: this.startDate,
              date_to: this.endDate,
            })
            .then((response) => {
              const filename = response.data.result.report_name;
              this.axios
                .get(`reports/${filename}`, { responseType: "blob" })
                .then((response) => {
                  const blob = new Blob([response.data], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
                  });
                  const link = document.createElement("a");
                  link.href = URL.createObjectURL(blob);
                  link.download = filename;
                  link.click();
                  URL.revokeObjectURL(link.href);
                });
            });
      }
    },
  },
};
</script>

<style scoped></style>
